<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    id="modal-refaz"
    centered
    size="lg"
    hide-footer
    cancel-variant="default"
    @hide="clearDados"
    @show="loadImpressoras"
  >
    <template v-if="isLoad">
      <template slot="modal-header">
        <h4 class="modal-title">Imprimir arquivo refaz</h4>
        <a
          class="btn btn-danger btn-close"
          @click="$bvModal.hide('modal-refaz')"
        ></a>
      </template>
      <form @submit.prevent="onFormSubmit">
        <div class="row">
          <div class="col-12 mb-2">
            <label class="form-label">Impressora:</label>
            <select class="form-select" required v-model="impressora">
              <option
                v-for="(item, i) in impressorasOptions"
                :key="i"
                :value="item.impressora"
              >
                {{ item.nome_exibicao }}
              </option>
            </select>
          </div>
          <div class="col-6 mb-2">
            <label class="form-label">Nome do arquivo:</label>
            <input
              class="form-control"
              required
              readonly
              type="text"
              :value="ordem.nome_exibicao"
            />
          </div>

          <div class="col-3 mb-2">
            <label class="form-label">Caixa:</label>
            <input
              class="form-control"
              required
              readonly
              type="text"
              :value="ordem.caixa"
            />
          </div>
          <div class="col-3 mb-2">
            <label class="form-label">Qtd. Objetos:</label>
            <input
              class="form-control"
              required
              readonly
              type="text"
              :value="ordem.qtd_objetos"
            />
          </div>

          <div class="w-100 d-flex justify-content-between mt-3">
            <a
              class="me-2 rounded btn btn-danger btn-sm"
              @click="$bvModal.hide('modal-refaz')"
            >
              <i class="fa fa-close me-2" />Fechar
            </a>
            <button
              v-if="checkFuncionalidade(3)"
              :disabled="saving"
              type="submit"
              class="d-flex align-items-center me-2 rounded btn btn-warning btn-sm"
            >
              <b-spinner v-if="saving" small class="me-2" />
              <i class="fa fa-save me-2" />Imprimir
            </button>
          </div>
        </div>
      </form>
    </template>
  </b-modal>
</template>
<script>
import axios from 'axios'
import CheckFunc from '../../../../mixins/CheckFunc'

export default {
  name: 'ModalProduto',
  props: ['ordem', 'loadItens'],
  data() {
    return {
      isLoad: true,
      saving: false,
      impressora: '',
      arquivo: '',
      teste: false,
      impressorasOptions: []
    }
  },
  mixins: [CheckFunc],
  methods: {
    clearDados() {
      this.arquivo = ''
      this.impressora = ''
      this.teste = false
    },
    async loadImpressoras() {
      this.isLoad = false
      await axios
        .get('producao/impressora')
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.impressorasOptions = res
        })
      this.arquivo = this.ordem.arquivo
      this.isLoad = true
    },
    async onFormSubmit() {
      this.saving = true
      let data = {
        arquivo: this.arquivo,
        impressora: this.impressora
      }

      let url = 'producao/imprimir-refaz'
      let method = 'post'

      await axios({ method, url, data })
        .then(res => {
          return res.data
        })
        .then(res => {
          this.$notify({
            group: 'top-center',
            title: `Arquivo refaz enviado para impressão!`,
            text: res.mensagem,
            type: 'success'
          })
          this.fecharModal()
          this.loadItens()
        })
        .catch(error => {
          this.$notify({
            group: 'top-center',
            title: 'Erro ao imprimir',
            text: error.response.data.mensagem,
            type: 'error'
          })
        })
      this.saving = false
    },
    fecharModal() {
      this.$bvModal.hide('modal-refaz')
    }
  }
}
//mostrar paginas varias if multivias true
</script>

<style scoped></style>
